import { contactSubmit } from './contact.js';
import FilterUnits from './services/FilterUnits.js';
import Map from "./services/Map.js";
import Overlay from "./services/Overlay.js";
import Slider from "./services/Slider.js";

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if(document.getElementById('facility_info_toggle')){
	document.getElementById('facility_info_toggle').addEventListener('click', (e) => {
		e.currentTarget.toggleAttribute('open');
		document.querySelector('.rates .wrapper-info').toggleAttribute('open');
	});
}

document.addEventListener('DOMContentLoaded', () => {
	const overlay = new Overlay();
	const facilitySlider = new Slider('[data-slider="facility-images"]');
	const locationsMap = new Map('[data-map="locations-map"]');
	const reviewsSlider = new Slider('[data-slider="reviews"]');
	const filterUnits = new FilterUnits('[data-js="facility-rates-node"]');

	// Toggle Mobile Menu
	(() => {
		const navOpenBtn = document.querySelector('[data-js="nav-open"]');
		const navCloseBtn = document.querySelector('[data-js="nav-close"]');
		const navMenu = document.querySelector('[data-js="nav-menu"]');

		if (!navMenu) {
			return;
		}

		if (navOpenBtn) {
			navOpenBtn.addEventListener("click", () => {
				navMenu.classList.add("active");
			});
		}

		if (navCloseBtn) {
			navCloseBtn.addEventListener("click", () => {
				navMenu.classList.remove("active");
			});
		}
	})();

	// Main - Toggle Hero blocks
	(() => {
		const toggleBlocks = [...document.querySelectorAll('[data-js="toggle-block"]')];

		if (!toggleBlocks.length) {
			return;
		}

		toggleBlocks.forEach(toggle => {
			toggle.addEventListener('click', () => {
				toggleBlocks.forEach(toggle => toggle.classList.remove('active'));
				toggle.classList.add('active');
			});
		});
	})();

	/* eslint-disable */
	//Chat widget
	(() => {
		var webchat;
		((window, document, node, props, configs) => {
			if (window.TalkdeskChatSDK) {
				console.error("TalkdeskChatSDK already included");
				return;
			}
			var divContainer = document.createElement("div");
			divContainer.id = node;
			document.body.appendChild(divContainer);
			var src = `https://talkdeskchatsdk.talkdeskapp.com/talkdeskchatsdk.js`;
			var script = document.createElement("script");
			var firstScriptTag = document.getElementsByTagName("script")[0];
			script.type = "text/javascript";
			script.charset = "UTF-8";
			script.id = "tdwebchatscript";
			script.src = src;
			script.async = true;
			firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
			script.onload = () => {
				webchat = TalkdeskChatSDK(node, props);
				webchat.init(configs);
				/*
				 * Send custom data from your website to TalkDesk!
				 * If you would like to do it, you need to remove the following commented code and
				 * modify the webchat.setContextParam parameters to pass in the data you need.
				 */
				/*function setContext() {
				  webchat.setContextParam({ "var1": "value1", "var2": "value2", "var3": 100 })
				}
				// Send data when the chat conversation is initiated
				webchat.onConversationStart = function() {
				  setContext()
				}
				// Send data when the chat widget is open
				webchat.onOpenWebchat = function() {
				  setContext()
				}*/
			};
		})(
			window,
			document,
			"tdWebchat",
			{ touchpointId: "e1c003fa41a44f6fbce921e0973e0eac", accountId: "", region: "td-us-1" },
			{
				enableValidation: false, enableEmoji: true, enableUserInput: true, enableAttachments: false, styles: {


					'chatTitle': 'XPSchat',
					'chatSubtitle': 'Powered by XPS Solutions',
					'chatThemeColor': '#00A2C5',
					'chatHoverThemeColor': '#337a97',
					'chatSubtitleTextColor': '#fff',
					'chatCloseButtonColor': '#fff',
					'welcomeMessageBackgroundColor': '#337a97',
					'buttonTextColor': '#00A2C5',
				}
			}
		);
	})();
	/* eslint-enable */
});


